<template>
  <div class="getCodeByTel">
    <el-input class='codeInputBox' 
      placeholder="请输入短信验证码" 
      v-model.trim="selfCode"
      >
    </el-input>
    <div class="codeNum" @click='myGetCode'>{{num===0?`获取验证码`:`${num}秒后重新获取`}}</div>
  </div>
</template>

<script>
import {computed, defineComponent} from 'vue'
export default defineComponent({
  name: 'getCodeByTel',
  props:{
    isBegin:{
      type:Boolean,
      require:true
    },
    code:{
      type:String,
      require:true
    },
    changeCode:{
      type:Function,
      require:true
    },
  },
  unmounted(){
    this.clearInterv()
  },
  data(){
    return {
      num:0,
      timer:null
    }
  },
  watch:{
    isBegin:{
      handler(newv){
        if(newv){
          this.num=59;
          this.countNum()
        }
      },
      immediate:true
    }
  },
  methods:{
    clearInterv(){
      if(this.timer){
        clearInterval(this.timer);
      }
    },
    countNum(){
      this.timer = setInterval(()=>{
        this.num --;
        if(this.num===0){
          this.clearInterv()
        }
      },1000)
    },
    myGetCode(){
      if(this.num===0){
        this.num = 59;
        this.countNum()
        // 发送验证码
        this.$emit('sendCode')
      }else{
        return
      }
    }
  },
  setup(props){
    const selfCode = computed({
      get(){
        return props.code
      },
      set(val){
        props.changeCode(val)
      }
    });
    return {
      selfCode
    }
  }
})
</script>
<style scoped lang="scss">
@import '@/assets/style/comman.scss';
.getCodeByTel{
  width:340px;
  width:100%;
  position: relative;
  .codeNum{
    position: absolute;
    right: 15px;
    top:0;
    font-size: 12px;
    color:$main-color;
    &:hover{
      cursor: pointer;
    }
  }
  .codeInputBox{
    width:100%;
    margin:0 auto;
    ::v-deep{
      .el-input__inner{
        border-radius:20px;
      }
    }
  }
}

</style>
