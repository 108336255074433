<template>
  <div class='loginBox'>
    <div class="mainBox">
      <div class="logoBox"></div>
      <div class="centerBox">
        <div class="title">登录</div>
        <div class="title2">重置密码</div>
        <myStep 
        :stepArr = 'stepArr'
        :active='active'></myStep>
        <el-form 
          :model="resetRuleForm" 
          :rules="rules" 
          ref="ruleForm" 
          >
          <el-form-item prop="tel" v-if='active===0'>
            <el-input class='resetInput' 
            placeholder="注册时使用的手机号码" 
            v-model.trim="resetRuleForm.tel"
            clearable>
            </el-input>
          </el-form-item>
          <el-form-item class='codeBox' v-if='active===1'>
            <getCode 
            :code='resetRuleForm.code' 
            :changeCode='changeCode'
            @sendCode='sendCode'
            :isBegin='isBegin'></getCode>
          </el-form-item>
          <div class="pwdBox" v-if='active===2'>
            <el-form-item prop="pwd">
              <el-input class='resetInput' 
              placeholder="请输入新密码" 
              show-password
              v-model.trim="resetRuleForm.pwd"
              clearable>
              </el-input>
            </el-form-item>
            <el-form-item prop="rePwd">
              <el-input class='resetInput resetInput2' 
              placeholder="请再次输入新密码" 
              show-password
              v-model.trim="resetRuleForm.rePwd"
              clearable>
              </el-input>
            </el-form-item>
          </div>
          <div class="resetPwdSuccess" v-if='active===3'>密码重置成功!</div>
        </el-form>
        <el-button 
        :loading='btnLoading' 
        class='mainBtn' 
        :disabled='active===1&&disabledCode'
        @click='stepNext'>{{btnText}}</el-button>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue';
import myStep from '@/components/step.vue';
import getCode from '@/components/getCodeByTel.vue';
import {isTel} from '@/utils/index.js';
import {sendVerifyCode,forgetPwd,validateVerifyCode} from '@/api/index.js';
export default defineComponent({
  components:{
    myStep,
    getCode
  },
  data(){
    const validatePass = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请输入密码'));
      } else {
        callback();
      }
    };
    const validatePass2 = (rule, value, callback) => {
      if (value === '') {
        callback(new Error('请再次输入密码'));
      } else if (value !== this.resetRuleForm.pwd) {
        callback(new Error('两次输入密码不一致'));
      } else {
        callback();
      }
    };
    return {
      rules:{
        tel:[
          { required: true, message: '请输入手机号', trigger: 'blur'},
          { pattern:isTel, message: '手机号格式错误', trigger: 'blur'}
        ],
        pwd:[
          { validator: validatePass, trigger: 'blur' }
        ],
        rePwd:[
          { validator: validatePass2, trigger: 'blur' }
        ]
      },
      resetRuleForm:{
        tel:'',
        code:'',
        pwd:'',
        rePwd:''
      },
      active:0,
      btnText:"获取验证码",
      stepArr:['输入手机号','验证手机号','重置密码'],
      isBegin:false,
      btnLoading:false,
      disabledCode:true
    }
  },
  methods:{
    changeCode(val){
      this.resetRuleForm.code = val
    },
    async sendCode(){
      let codeRes = await sendVerifyCode({
        tel:this.resetRuleForm.tel
      });
      console.log(codeRes)
    },
    async stepNext(){
      if(this.active === 3){
        this.$router.push('/login')
      }else{
        if(this.active === 0){
          // 输入手机号，获取验证码
          console.log('输入手机号，获取验证码')
          this.$refs['ruleForm'].validateField('tel',async tellErr=>{
            if(tellErr){
              console.log('tellErr:',tellErr)
            }else{
              this.btnLoading = true;
              let codeRes = await sendVerifyCode({
                tel:this.resetRuleForm.tel
              });
              this.btnLoading = false;
              if(codeRes&&codeRes.code==200){
                this.btnText = '验 证';
                this.active += 1;
              };
            }
          })
        }else if(this.active === 1){
          // 输入验证码，验证手机号
          console.log('输入验证码，验证手机号')
          this.btnLoading = true;
          let validateCode = await validateVerifyCode({
            code:this.resetRuleForm.code,
            tel:this.resetRuleForm.tel,
            throwEx:true
          });
          this.btnLoading = false;
          console.log(validateCode)
          if(validateCode&&validateCode.code==200&&validateCode.data.success){
            this.btnText = '重 置';
            this.active += 1
          };
          
        }else if(this.active === 2){
          // 重置密码
          console.log('重置密码')
          this.$refs['ruleForm'].validateField('pwd',async pwdErr=>{
            if(pwdErr){
              console.log('pwdErr:',pwdErr)
            }else{
              this.$refs['ruleForm'].validateField('rePwd',async rePwdErr=>{
                if(rePwdErr){
                  console.log('rePwdErr:',rePwdErr)
                }else{
                  this.btnLoading = true;
                  let resetRes = await forgetPwd({
                    tel:this.resetRuleForm.tel,
                    newPassword:this.resetRuleForm.pwd,
                    newPasswordAgain:this.resetRuleForm.rePwd
                  });
                  this.btnLoading = false;
                  console.log(resetRes)
                  if(resetRes&&resetRes.code==200){
                    this.btnText = '去登录';
                    this.active += 1
                  }
                }
              })
            }
          })
          
        };
      };
  }
  },
  watch:{
    active(newv,oldv){
      if(newv===1){
        this.isBegin = true;
      }
    },
    'resetRuleForm.code'(newV,oldV){
      if(this.active==1&&newV){
        this.disabledCode = false
      }else{
        this.disabledCode = true
      }
    }
  }

  
})
</script>

<style scoped lang='scss'>
@import '@/assets/style/comman.scss';
.loginBox{
  width:100%;
  height:100%;
  background: #FFC696;
  position: relative;
  .mainBox{
    @include container;
    height:100%;
    background: rgba(0, 0, 0, 0);
    display: flex;
    align-items: center;
    justify-content: center;
    .centerBox{
      width:500px;
      height:530px;
      box-sizing: border-box;
      margin:auto;
      padding:30px;
      display: flex;
      flex-direction: column;
      align-items: center;
      background: #fff;
      border-radius:10px;
      box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.2);
      position: relative;
      .title{
        font-size: 32px;
        font-weight: 700;
        letter-spacing: 5px;
        text-align: center;
        width:100%;
        color:$main-color
      }
      .title2{
        font-size: 15px;
        width:100%;
        text-align: center;
        color:#555;
        margin:60px auto 30px auto;
      }
      .mainBtn{
        @include mainBtn;
        border-radius:20px;
        position: absolute;
        bottom:50px;
        left:50%;
        margin-left:-180px
      }
      .resetInput{
        width:340px;
        margin:50px auto 10px auto;
        ::v-deep{
          .el-input__inner{
            border-radius:20px;
          }
        }
      }
      .codeBox{
        width:340px;
        margin-top:50px;
        
      }
      .pwdBox{
        ::v-deep{
          .resetInput{
            margin-bottom:0
          }
          .resetInput2{
            margin:10px auto 0 auto
          }
        }
      }
      .resetPwdSuccess{
        width:200px;
        height:200px;
        color:$main-color;
        letter-spacing: 1px;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

</style>